.video-dialog {
  border: none;
  border-radius: 8px;
  padding: 0;
  overflow: visible;
  backdrop-filter: blur(5px);
  max-width: 90%;
  margin: auto; /* Centers the dialog horizontally */
}

.video-dialog::backdrop {
  background: rgba(0, 0, 0, 0.825); /* Darken background for modal effect */
}

.video-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: -40px;
  right: -8px;
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
