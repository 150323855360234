/* remove the styling of the a tag in the class no-styling-recuit-a */




/* Your global or component-specific CSS file */

/* Reset styles for <a> tag */
a.career-item-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit text color from parent */
    display: block; /* Make sure the <a> tag behaves like a block */
  }
  
/* Ensure the cursor changes to a pointer to indicate it's clickable */
.career-item {
  cursor: pointer;
}

.apply-frm > .row > .form-group > input,
.apply-frm > .row > .form-group > textarea {
  border: none !important;
  outline: none; /* Remove default focus outline */
  box-shadow: none; /* Remove any default box-shadow */
  transition: border 0.2s ease; /* Optional: smooth transition */
}

/* Custom focus style */
.apply-frm > .row > .form-group > input:focus,
.apply-frm > .row > .form-group > textarea:focus {
  border: 2px solid #4DB3B3; /* Add custom border color on focus */
  outline: none; /* Remove any focus outline */
  box-shadow: 0 0 5px rgba(77, 179, 179, 0.5); /* Optional: add subtle shadow */
  transition: box-shadow 0.2s ease; /* Smooth transition */
}

.required {
  color: red;
}


.applyFormBtn {
  border: none;
}

.applyFormBtn.send{
  background-color: #1C5B2E;
  color: white;
}

.applyFormBtn.send:hover{
  border-color: #1a8643;
  background-color: #1a8643;
  color: #fff;
}

.applyFormBtn.close:hover{
  border-color: #f1fcf3;
  background-color: #f1fcf3;
  color: #333;
}

.applyUploadBtn{
  background-color: #1a8643 !important;
  color: white;
}

.textBtn {
  border: none;
  background-color: #ffffff00 !important;
}

.textBtn:hover {
  background-color:#f5f5f5 !important;
}

.submitBtn {
  border: 1.5px solid #229742 !important;
  background-color: #ffffff !important;
}

.submitBtn:hover {
  background-color: #2297411c !important;
}

.gender-selector {
  height: 2.1875rem !important;
}

.gender-option {
  margin-bottom: 0 !important;
  display: flex !important;
  align-items: center !important; /* Aligns radio and text vertically */
  justify-content: center !important;
  gap: 8px; /* Adjust spacing between radio button and text */
}


.recuit-box {
  border: 2px solid #e5e5e5;
  padding: 1rem;
  border-radius: 1rem;
}
/* 
.info-form {
  overflow-y: auto !important;
  min-height: 60vh !important;
}

@media screen and (max-width: 579px) {
  .info-form {
    overflow-y: auto !important;
    min-height: 80vh !important;
  }
} */
