/* SectionContentTable1.css */

  
  /* Styling for table rows */
.special {
    background-color: #ffffff;
  }

/* Styling for icons */
.service-content-table .fa-check {
  color: green;
}

.service-content-table .fa-xmark {
  color: red;
}

.service-content-table table {
  background-color: #F1FCF3;

} 

.text-center {
  text-align: center !important;
} 


  