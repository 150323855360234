/* Swipe Left */
.question-transition-left-enter {
    opacity: 0;
    transform: translateX(-100%);
    position: absolute;
  }
  .question-transition-left-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 333ms ease-in-out, opacity 333ms ease-in-out;
    position: absolute;
  }
  .question-transition-left-exit {
    opacity: 1;
    transform: translateX(0);
    position: absolute;
  }
  .question-transition-left-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 333ms ease-in-out, opacity 333ms ease-in-out;
    position: absolute;
  }
  
  /* Swipe Right */
  .question-transition-right-enter {
    opacity: 0;
    transform: translateX(100%);
    position: absolute;
  }
  .question-transition-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 333ms ease-in-out, opacity 333ms ease-in-out;
    position: absolute;
  }
  .question-transition-right-exit {
    opacity: 1;
    transform: translateX(0);
    position: absolute;
  }
  .question-transition-right-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 333ms ease-in-out, opacity 333ms ease-in-out;
    position: absolute;
  }
  