.news-item:hover{
    cursor: pointer;
}

.news-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    height: 7rem;
}

.article-content img{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;

}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .news-item img {
        height: 10rem;
    }
}
