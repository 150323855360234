/* h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
} */

a {
  text-decoration: none !important; /* Disable the underline */
  color: inherit; /* Ensure the color inherits from the parent */
}

.cursor-pointer {
  cursor: pointer;
}

.no-overflow-y{
  overflow-y: visible !important; ;
}

/* Dashboard */
/* ------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */


.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.wrapper h5.glitch {
  color: black;
  font-family: sans-serif;
  font-weight: 800;
  position: relative;
  font-size: 0.8rem;
}
.wrapper h5.glitch::before, .wrapper h5.glitch::after {
  color: black;
  content: "Still in beta so thanks for testing🫶";
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  top: 0;
  font-size: 0.8rem;
}
.wrapper h5.glitch::before {
  left: 3px;
  text-shadow: -2px 0 red;
  animation-name: glitch-animation-1;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
.wrapper h5.glitch::after {
  left: -3px;
  text-shadow: -2px 0 blue;
  animation-name: glitch-animation-2;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes glitch-animation-1 {
  0% {
    clip: rect(147px, 350px, 91px, 30px);
  }
  5% {
    clip: rect(51px, 350px, 97px, 30px);
  }
  10% {
    clip: rect(53px, 350px, 46px, 30px);
  }
  15% {
    clip: rect(18px, 350px, 1px, 30px);
  }
  20% {
    clip: rect(92px, 350px, 124px, 30px);
  }
  25% {
    clip: rect(45px, 350px, 41px, 30px);
  }
  30% {
    clip: rect(2px, 350px, 17px, 30px);
  }
  35% {
    clip: rect(141px, 350px, 88px, 30px);
  }
  40% {
    clip: rect(101px, 350px, 128px, 30px);
  }
  45% {
    clip: rect(103px, 350px, 127px, 30px);
  }
  50% {
    clip: rect(6px, 350px, 1px, 30px);
  }
  55% {
    clip: rect(20px, 350px, 138px, 30px);
  }
  60% {
    clip: rect(10px, 350px, 54px, 30px);
  }
  65% {
    clip: rect(66px, 350px, 11px, 30px);
  }
  70% {
    clip: rect(80px, 350px, 68px, 30px);
  }
  75% {
    clip: rect(45px, 350px, 139px, 30px);
  }
  80% {
    clip: rect(2px, 350px, 135px, 30px);
  }
  85% {
    clip: rect(75px, 350px, 124px, 30px);
  }
  90% {
    clip: rect(147px, 350px, 64px, 30px);
  }
  95% {
    clip: rect(10px, 350px, 147px, 30px);
  }
  100% {
    clip: rect(49px, 350px, 16px, 30px);
  }
}
@keyframes glitch-animation-2 {
  0% {
    clip: rect(116px, 350px, 65px, 30px);
  }
  5% {
    clip: rect(95px, 350px, 86px, 30px);
  }
  10% {
    clip: rect(5px, 350px, 137px, 30px);
  }
  15% {
    clip: rect(119px, 350px, 28px, 30px);
  }
  20% {
    clip: rect(93px, 350px, 118px, 30px);
  }
  25% {
    clip: rect(108px, 350px, 94px, 30px);
  }
  30% {
    clip: rect(109px, 350px, 111px, 30px);
  }
  35% {
    clip: rect(54px, 350px, 54px, 30px);
  }
  40% {
    clip: rect(125px, 350px, 121px, 30px);
  }
  45% {
    clip: rect(11px, 350px, 43px, 30px);
  }
  50% {
    clip: rect(57px, 350px, 44px, 30px);
  }
  55% {
    clip: rect(68px, 350px, 111px, 30px);
  }
  60% {
    clip: rect(61px, 350px, 108px, 30px);
  }
  65% {
    clip: rect(131px, 350px, 24px, 30px);
  }
  70% {
    clip: rect(141px, 350px, 81px, 30px);
  }
  75% {
    clip: rect(120px, 350px, 98px, 30px);
  }
  80% {
    clip: rect(47px, 350px, 16px, 30px);
  }
  85% {
    clip: rect(57px, 350px, 30px, 30px);
  }
  90% {
    clip: rect(77px, 350px, 45px, 30px);
  }
  95% {
    clip: rect(87px, 350px, 15px, 30px);
  }
  100% {
    clip: rect(90px, 350px, 80px, 30px);
  }
}

.clickable {
  cursor: pointer;  
}

/* Remove the default focus outline for input, textarea, and select */
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none; /* Remove the browser's default focus outline */
  box-shadow: none; /* Remove any shadow (used in some browsers) */
  border-color: inherit; /* Maintain consistent border color */
}


/* normal */
/* ------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */

#notfound {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  padding: 80px 0;
  color: #000;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0px;
  line-height: 1.5;
}

.notfound .notfound-404 h1 {
  font-size: 8rem;
  margin: 0px;
  font-weight: 900;
  color: #1c5b2e;
  background-size: cover;
  background-position: center;
}

.notfound h2 {
  color: #000;
  font-size: 2.2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound a {
  margin-top: 15px;
  font-size: 1.25rem;
  text-decoration: none;
  text-transform: uppercase;
  background: #1c5b2e;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
}

.notfound a:hover,
.notfound a:focus {
  color: #fff;
}

.page-title {
  margin-bottom: 10px;
}

.page-title h1{
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
}

.breadcrumb-item a{
  font-size: 18px;
}

.breadcrumb-gap{
  margin-left: 6px;
}

/* ------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */
/* appointment gender  select */
.gender-selector {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 80%
}

.recuitment-selector {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 80%;
}

.checkbox-option {
  display: flex !important;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: black; 
}

.checkbox-option input[type="radio"] {
  display: none; /* Hide default radio input */
}

.checkbox-option .custom-radio {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 0.222rem solid #229742; 
  display: inline-block;
  margin-right: 8px;
  transition: all 0.3s;
}

.checkbox-option.selected .custom-radio {
  background-color: #229742; /* Fill with brownish color when selected */
}


.frm-btn button {
  border: 0;
  width: 8.02083rem;
  border-radius: 99px;
}

.frm-btn.primary-form-btn button {
  background-color: #1C5B2E;
  color: white;
}

.frm-btn.primary-form-btn button:hover {
  background-color: #1A8643;
}

.form-group textarea {
  border: none !important;  
  outline: none !important;
  transition: box-shadow 0.3s ease !important;
}

.form-group textarea:focus,
.form-group textarea:active {
  outline: none; /* Remove default browser outline */
  border: none;  /* Remove default browser border highlight */
  box-shadow: none; /* Remove any default shadow applied by the browser */
}

/* Optional: Add your custom styling */
.form-group textarea {
  border-radius: 33px; /* Custom rounded corners */
  padding: 13px 10px 10px 1.04167rem;
  background-color: white; /* Optional background color */
  resize: none; /* Prevent resizing if needed */
  transition: border-color 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
}

.form-group .error-text {
  padding: 0 0.84167rem;
  font-size: clamp(12px, 0.83333rem, 1.04167rem);
}

/* BookingFormAppointment.css */
.booking-form {
  position: relative;
  overflow: hidden;
}

.form-transition-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.page-content {
  position: absolute;
  width: 100%;
  top: 0;
  transition: transform 0.5s ease-in-out;
}

.slide-in-left {
  transform: translateX(0);
}

.slide-in-right {
  transform: translateX(0);
}

.page-1 .page-content.slide-in-right,
.page-2 .page-content.slide-in-left {
}

.page-2 .page-content.slide-in-right,
.page-1 .page-content.slide-in-left {
}

.initial-load .page-content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.initial-load.page-1 .page-content,
.initial-load.page-2 .page-content {
  opacity: 1;
}

.btn-default:hover {
  background-color: #D6D6D6;
}

.view-list-btn {
  width: 12rem !important;
  background-color: #0C1B33 !important;
  color: white;
}

.view-list-btn:hover {
  background-color: #1B3D74 !important;
}

.custom-placeholder{
  color: '#77787b', 
}

.sub-box-parent {

  padding: 0 0 1.66667rem 1.66667rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sub-box {
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1), 0 -6px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  overflow-y: auto;
}

.sticky-button {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 1.66667rem;
  background: var(--gradient-3, linear-gradient(180deg, #1A8643, #1C5B2E));
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sticky-button span {
  font-size: 2rem;
}

.highlighted-slide .single-box-user-rating {
  background-color: #fff1ee;
  /* raise it up a litle bit with animation */
  transform: translateY(-0.75rem);
}

.single-box-user-rating {
	border: none;
	padding: 2.5rem 2rem 2.5rem;
	border-radius: 1.66667rem;;
	background-color:  #f1fcf3;
  margin-top: 1rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  transform: translateY(0);  /* Default position */
  transition: transform 2.5s ease-in-out, background-color 2.5s ease-in-out; /* Ensure smooth animation */
}

.highlighted-slide .single-box-user-rating .svg-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  transition: opacity 2.5s ease-in-out;
}


.single-box-user-rating::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130%;
  background: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' id='svg' viewBox='0 0 1440 390' xmlns='http://www.w3.org/2000/svg' class='transition duration-300 ease-in-out delay-150'%3E%3Cpath d='M 0,400 L 0,150 C 67.02564102564105,174.06923076923078 134.0512820512821,198.13846153846154 203,196 C 271.9487179487179,193.86153846153846 342.8205128205127,165.51538461538462 439,137 C 535.1794871794873,108.4846153846154 656.6666666666669,79.80000000000001 753,94 C 849.3333333333331,108.19999999999999 920.5128205128203,165.2846153846154 990,175 C 1059.4871794871797,184.7153846153846 1127.2820512820513,147.06153846153848 1202,135 C 1276.7179487179487,122.93846153846154 1358.3589743589744,136.46923076923076 1440,150 L 1440,400 L 0,400 Z' stroke='none' stroke-width='0' fill='%23f1eeff' fill-opacity='1' class='transition-all duration-300 ease-in-out delay-150 path-0'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1 !important;
  opacity: 0;  /* Ensure it starts invisible */
  transform: translateY(20px); /* Start slightly lower */
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out; /* Smooth fade & movement */
}


/* When highlighted-slide is added */
.highlighted-slide .single-box-user-rating::after {
  opacity: 1;
  transform: translateY(0); /* Move back into place */
}

.single-box-user-rating .img-area {
	margin: 2.75rem 0 1.5rem;
}

.single-box-user-rating img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
  border: 0.75rem solid white; /* White border around image */
  position: relative;
  z-index: 2; /* Ensure it's above the curved background */
  margin: 0 auto;
}

.single-box-user-rating .content {
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
  max-height: 15rem;
  overflow: auto;
}

.custom-scrollbar {
  overflow-y: auto;
  padding-right: 15px; /* Prevents content from shifting */
}


/* WebKit (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: hsl(0, 0%, 100%); /* Track background */
  border-radius: 10px;
  margin: 5px; /* Adds space between scrollbar and content */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar color */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover effect */
}


.single-box-user-rating .content p {
  text-align: left;
}


.single-box-user-rating h5 {
	font-weight: 600;
	margin: 0;
	font-size: 1.5rem;
}

.single-box-user-rating {
	overflow: hidden
}

.single-box-user-rating .img-area {
	width: 100%;
	float: left;
	margin: 0;

}


.single-box-user-rating p {
	margin: 10px 0 25px;
	line-height: 2.3;
  font-size: 1rem;
}

.single-box-user-rating .stars {
  color: #ffc400;
  font-size: 1.5rem;
}

.hidden-mobile {
  display: block;
}

.overflow-show {
  overflow: visible !important;
}

.feed-back-home-section-title {
	font-size: 28px;
	margin-bottom: 20px;
	padding-bottom: 20px;
	font-weight: 400;
	display: inline-block;
	position: relative;
}
.feed-back-home-section-title:after,
.feed-back-home-section-title:before {
	content: "";
	position: absolute;
	bottom: 0;
}
.feed-back-home-section-title:after {
	height: 2px;
	background-color: #229742;
	left: 25%;
	right: 25%;
}

.feed-back-home-section-title:before {
	width: 15px;
	height: 15px;
	border: 3px solid #fff;
	background-color: #1A8643;
	left: 50%;
	transform: translatex(-50%);
	bottom: -6px;
	z-index: 9;
	border-radius: 50%;
}

.content-box-feedback {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it fills the parent container */
  max-height: 90vh;
  background-color: #C0F2CD;
  border-radius: 1.66667rem;
  padding: 1.25rem 1.66667rem;
  overflow: hidden;
}

.height {
  height: 100%;
}

.content-box-feedback > div:not(.content-box-feedback-title) {
  flex-grow: 1; /* Pushes NavigationCustom to the bottom */
}

.content-box-feedback .swiper-navigation {
  display: flex;
  align-items: end;
  justify-content: end !important; 
}

.border-radius {
  border-radius: 1.66667rem;
}

.btn-outline {
  background-color: transparent;
  color: #1C5B2E;
  border: 2.5px solid #1C5B2E;
}

.btn-outline:hover {
  background-color: #229742;
  border: 2.5px solid #229742;
  color: white;
  cursor: unset;
}

.feedback-main-title {
  font-size: 1.5rem;
  font-weight: 550;
  /* adjust the font spacing */
  letter-spacing: 0.5px;
  line-height: 1.75rem;
  color: #1C5B2E;
}

.feedback-hr {
  border: none;
  height: 0.15rem;
  background: #1C5B2E;
  border-radius: 1rem;
  width: 5rem;
  margin-left: 0px;
} 

.feedback-content {
  flex-grow: 1; /* Allow it to take available space */
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
  max-height: 12.5rem; /* Set a maximum height */
  padding-right: 10px; /* Prevent scrollbar from overlapping content */
  line-height: 1.25rem;
}

.feedback-name {
  font-weight: 500;
}

.feedback-title {
  color: #848484;
}

@media screen and (max-width: 1024px) {
  .hidden-mobile {
    display: none!important;  
  }
} 

@media screen and (min-width: 992px) {
  .sub-box {
    max-height: 650px;
  } 
  
}


