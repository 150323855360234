.header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41 ,112, 0.1);
    background-color: #fff;
    padding-left: 20px;
}

.header  .toggle-sidebar-btn{
    font-size: 20px;
    padding-left: 10px;
    cursor: pointer;
    color: #012970;
}