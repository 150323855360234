/* Toàn bộ trang */
/* body {
  background-color: #f4f6f9;
  font-family: Arial, sans-serif;
} */

/* Nội dung đăng nhập */
.loginContent {
  margin-top: 3rem;
  margin-bottom: 6rem;
}

@media (max-width: 1025px) {
  .loginContent {
    margin-top: 0; /* Hoặc giá trị khác mà bạn muốn */
    margin-bottom: 3rem;
  }
}

/* Khung đăng nhập */
.login-box {
  width: 400px;
  padding: 20px;
  background: white;
  border-radius: 1.25rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: auto; /* Giúp căn giữa khung */
}

/* Tiêu đề */
.login-logo {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Thẻ card */
.card {
  border: none;
}

.login-card-body {
  padding: 20px;
}

/* Thông điệp đăng nhập */
.login-box-msg {
  margin-bottom: 20px;
  text-align: center;
}

/* Trường nhập liệu */
.form-control {
  border: 1px solid #ced4da !important;
  border-radius: 1.25rem;
  padding: 10px;
  font-size: 16px;
  width: 100%;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Thông báo lỗi */
.alert {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

