@import url('https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-container {
	--ckeditor5-preview-sidebar-width: 270px;
	--ckeditor5-preview-height: 475px;
	font-family: 'Lato';
	width: 100%;

}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container__editor-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
}

.editor-container_document-editor {
	border: 1px solid var(--ck-color-base-border);
}

.editor-container_document-editor .editor-container__toolbar {
	display: flex;
	position: relative;
	box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar > .ck.ck-toolbar {
	flex-grow: 1;
	width: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-top: 0;
	border-left: 0;
	border-right: 0;
}

.editor-container_document-editor .editor-container__menu-bar > .ck.ck-menu-bar {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-top: 0;
	border-left: 0;
	border-right: 0;
}

.editor-container_document-editor .editor-container__editor-wrapper {
	max-height: var(--ckeditor5-preview-height);
	min-height: var(--ckeditor5-preview-height);
	overflow-y: scroll;
	overflow-x: scroll;
	background: var(--ck-color-base-foreground);
}

.editor-container_document-editor .editor-container__editor {
	margin-top: 28px;
	margin-bottom: 28px;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;

}

.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
	box-sizing: border-box;
	min-width: calc(210mm + 2px);
	max-width: calc(420mm + 2px);
	min-height: 297mm;
	height: fit-content;
	padding: 20mm 12mm;
	border: 1px hsl(0, 0%, 82.7%) solid;
	background: hsl(0, 0%, 100%);
	box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
	flex: 1 1 auto;
	margin-left: 10rem;
	margin-right: 10rem;
}

.editor-container__sidebar {
	min-width: var(--ckeditor5-preview-sidebar-width);
	max-width: var(--ckeditor5-preview-sidebar-width);
	margin-top: 28px;
	margin-left: 10px;
	margin-right: 10px;
}

.editor-container__minimap-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.editor-container__sidebar_minimap {
	margin: 0;
	flex: 0 0 auto;
	border-left: 1px solid var(--ck-color-toolbar-border);
	position: relative;
	overflow: hidden;
	max-height: 100%;
}

@media screen and (max-width: 1088px) {
	.editor-container__sidebar_minimap {
	  display: none;
	}

	.editor-container_include-minimap.ck-minimap__iframe-content .ck.ck-editor__editable {
		display: none;
	}

}

@media screen and (max-width: 955px) {
	.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable{
		min-width: calc(170mm + 2px);
	}
}

@media screen and (max-width: 810px) {
	.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable{
		min-width: calc(110mm + 2px);
	}
}

@media screen and (max-width: 572px) {
	.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable{
		min-width: calc(70mm + 2px);
	}
}



.editor-container_include-minimap.ck-minimap__iframe-content {
	background: hsl(0, 0%, 100%);
}

.editor-container_include-minimap.ck-minimap__iframe-content .ck.ck-editor__editable {
	font-family: 'Lato';
	line-height: 1.6;
	flex: 1 1 auto;
	word-break: break-word;
	box-sizing: border-box;
	min-width: calc(210mm + 2px);
	max-width: calc(420mm + 2px);
	min-height: 297mm;
	height: fit-content;
	padding: 20mm 12mm;
}



.editor-container_include-minimap .editor-container__editor-wrapper {
	height: 100%;
}

.editor-container_include-minimap .editor-container__sidebar_minimap {
	max-width: calc(var(--ckeditor5-preview-sidebar-width) / 2);
	min-width: calc(var(--ckeditor5-preview-sidebar-width) / 2);

}

.ck-content h3.category {
	font-family: 'Oswald';
	font-size: 20px;
	font-weight: bold;
	color: #555;
	letter-spacing: 10px;
	margin: 0;
	padding: 0;
}

.ck-content h2.document-title {
	font-family: 'Oswald';
	font-size: 50px;
	font-weight: bold;
	margin: 0;
	padding: 0;
	border: 0;
}

.ck-content h3.document-subtitle {
	font-family: 'Oswald';
	font-size: 20px;
	color: #555;
	margin: 0 0 1em;
	font-weight: bold;
	padding: 0;
}

.ck-content p.info-box {
	--background-size: 30px;
	--background-color: #e91e63;
	padding: 1.2em 2em;
	border: 1px solid var(--background-color);
	background: linear-gradient(
			135deg,
			var(--background-color) 0%,
			var(--background-color) var(--background-size),
			transparent var(--background-size)
		),
		linear-gradient(
			135deg,
			transparent calc(100% - var(--background-size)),
			var(--background-color) calc(100% - var(--background-size)),
			var(--background-color)
		);
	border-radius: 10px;
	margin: 1.5em 2em;
	box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
	font-family: 'Oswald';
	font-style: normal;
	float: right;
	width: 35%;
	position: relative;
	border: 0;
	overflow: visible;
	z-index: 1;
	margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
	content: '“';
	position: absolute;
	top: -37px;
	left: -10px;
	display: block;
	font-size: 200px;
	color: #e7e7e7;
	z-index: -1;
	line-height: 1;
}

.ck-content blockquote.side-quote p {
	font-size: 2em;
	line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
	font-size: 1.3em;
	text-align: right;
	color: #555;
}

.ck-content span.marker {
	background: yellow;
}

.ck-content span.spoiler {
	background: #000;
	color: #000;
}

.ck-content span.spoiler:hover {
	background: #000;
	color: #fff;
}

.ck-content pre.fancy-code {
	border: 0;
	margin-left: 2em;
	margin-right: 2em;
	border-radius: 10px;
}

.ck-content pre.fancy-code::before {
	content: '';
	display: block;
	height: 13px;
	background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
	margin-bottom: 8px;
	background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
	background: #272822;
	color: #fff;
	box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
	background: #dddfe0;
	color: #000;
	box-shadow: 5px 5px 0 #b3b3b3;
}
